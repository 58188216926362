<template>
  <div
    v-if="loading"
    class="d-flex justify-content-center full-height full-width align-items-center"
  >
    <div class="loader m-t-45" :style="loaderStyle" />
  </div>
</template>

<script>
export default {
  name: "PgLoading",
  description: "shows a centered spinning icon based on state",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: "#6434D0",
    },
  },
  computed: {
    loaderStyle() {
      return {
        background: `radial-gradient(farthest-side, ${this.color} 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, ${this.color})`,
      };
    },
  },
};
</script>

<style>
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
